<template>
  <div class="messageBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">消息中心</div>
      <div class="topRight" @click="editEvent()" v-if="!flag">管理</div>
      <div class="topRight1" v-else @click="flag = false">取消</div>
    </div>
    <div class="yy"></div>
    <div class="box">
<!--      <div class="containerBox">-->
<!--        <div class="container">-->
<!--          <van-checkbox v-if="flag"></van-checkbox>-->
<!--          <van-swipe-cell>-->
<!--            <div class="content">-->
<!--              <div class="contentLeft"></div>-->
<!--              <div class="contentRight">-->
<!--                <div class="title">期望该离职吗？</div>-->
<!--                <div class="time">2023-06-18 21:13</div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <template #right>-->
<!--              <van-button-->
<!--                @click="delBtnEvent()"-->
<!--                square-->
<!--                text="删除"-->
<!--                type="danger"-->
<!--                class="delete-button"-->
<!--              />-->
<!--            </template>-->
<!--          </van-swipe-cell>-->
<!--          &lt;!&ndash; </van-checkbox> &ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->
      <div class="containerBox" v-for="item in trends" :key="item.id" @click="editDraft(item.id)">
        <div class="container">
          <van-checkbox v-if="flag"></van-checkbox>
          <van-swipe-cell>
            <div class="content">
              <div class="contentLeft" v-if="JSON.parse(item.imgs).length > 0">
                <img :src="JSON.parse(item.imgs)[0]">
              </div>
              <div class="contentRight">
                <div class="title">{{ item.title }}</div>
                <div class="time">{{ item.createTime }}</div>
              </div>
            </div>
            <template #right>
              <van-button
                @click="delBtnEvent()"
                square
                text="删除"
                type="danger"
                class="delete-button"
              />
            </template>
          </van-swipe-cell>
          <!-- </van-checkbox> -->
        </div>
      </div>
    </div>
    <div class="btnBox" v-if="flag">
      <div @click="delNull()">一键清空</div>
      <div @click="delEvent()">删除({{ this.delData.ids.length }})</div>
    </div>
    <!-- <van-popup v-model="showDel" :close-on-click-overlay="false">
        <div class="delBox"></div>
      </van-popup>-->
  </div>
</template>

<script>
import {
  messagePage,
  messageDel,
  delBatch,
  messageRead,
} from "../../api/lookAt";
import { Dialog } from "vant";
import { Toast } from "vant";
import { getTrendsDraft } from "@/api/trends";

export default {
  data() {
    return {
      flag: false,
      showDel: false,
      messList: [
        {
          id: 1,
          title: "标题",
          createTime: "2023-06-18 21:13",
          data: "内容",
        },
      ],
      delData: {
        ids: [],
      },
      search: {
        pageNum: 1,
        pageSize: 10,
      },
      trends:[],
    };
  },
  mounted() {
    this.getTrendsDraft();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    editEvent() {
      this.flag = true;
    },
    getTrendsDraft() {
      getTrendsDraft(this.search).then(res => {
        if (res.data.code === 0) {
          this.trends = res.data.data.items;
          if (res.data.data.items.length === this.search.pageSize) {
            this.search.pageNum = this.search.pageNum + 1;
          }
        }
      })
    },
    editDraft(id) {
      this.$router.push({path:'/sendPage', query: {trendsId: id}})
    },
    // 多个删除
    delEvent() {
      Dialog.confirm({
        title: "删除",
        message: "确定要删除吗？",
      })
        .then(() => {
          // on confirm
          delBatch(this.delData).then((res) => {
            console.log(res, "多个删除");
            if (res.data.code === 0) {
              Toast("删除成功");
              this.getmessagePage();
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 一键清空
    delNull() {
      let obj = {
        ids: [],
      };
      this.messList.forEach((i) => {
        console.log(i);
        obj.ids.push(i.id);
      });
      console.log(obj.ids);

      Dialog.confirm({
        title: "清空",
        message: "确定要清空吗？",
      })
        .then(() => {
          // on confirm
          delBatch(obj).then((res) => {
            console.log(res, "清空");
            if (res.data.code === 0) {
              Toast("删除成功");
              this.getmessagePage();
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 单个删除
    delBtnEvent(item) {
      let obj = {
        id: "",
      };
      obj.id = item.id;
      // console.log(item);
      Dialog.confirm({
        title: "删除",
        message: "确定要删除当前信息吗？",
      })
        .then(() => {
          messageDel({ obj }).then((res) => {
            console.log(res, "删除消息");
            if (res.data.code === 0) {
              Toast("删除成功");
              this.getmessagePage();
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    checkEvent(item, index) {
      console.log(item);
      this.delData.ids = [];
      this.messList[index].checked != item.checked;
      this.messList.forEach((i) => {
        if (i.checked) {
          this.delData.ids.push(i.id);
        }
      });
      console.log(this.delData.ids);

      this.$forceUpdate();
    },
    detailEvent(item) {
      console.log(item);
      this.$router.push({ path: "/messageDetail", query: { id: item.id } });
    },
  },
};
</script>

<style scoped>
.boxTop {
  width: 750px;
  height: 88px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: fixed;
  top: 0px;
  background: #ffffff;
  z-index: 999;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  width: 370px;
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.topCon img {
  width: 48px;
  height: 48px;
}
.topRight,
.topRight1 {
  width: 248px;
  font-size: 28px;
  font-weight: 600;
  color: #0b1526;
  text-align: right;
}
.yy {
  width: 750px;
  height: 20px;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.0001) 100%);
  border-radius: 0px 0px 0px 0px;
  opacity: 0.0176;
  margin-top: 100px;
}
.box {
  margin-bottom: 100px;
}
.goods-card {
  margin: 0;
  background-color: @white;
}
.delete-button {
  height: 100%;
}

.container {
  width: 100%;
  display: flex;
  /* align-items: center; */
}
.content {
  width: 718px;
  height: 208px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  margin-left: 32px;
  border-bottom: 1px solid #f6f8fa;
}
.contentLeft {
  width: 144px;
  height: 144px;
  background: pink;
  border-radius: 16px;
  /* margin-left: 32px; */
  overflow: hidden;
}
.contentLeft img {
  height: 100%;
  //border-radius: 50%;
}
.contentRight {
  margin-left: 32px;
}
.title {
  width: 500px;
  height: 110px;
  font-size: 32px;
  font-weight: 400;
  color: #0b1526;
}
.time {
  width: 190px;
  height: 40px;
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  margin-top: 8px;
}
/deep/.van-badge--fixed {
  position: absolute;
  top: -80px;
  right: 10px;
}
/deep/.van-swipe-cell {
  width: 100%;
}
.van-checkbox {
  width: 50px;
  height: 100px;
  margin-left: 30px;
  margin-right: 10px;
}
.btnBox {
  width: 750px;
  height: 150px;
  display: flex;
  position: fixed;
  bottom: 0px;
  background: #fff;
}
.btnBox div:nth-child(1) {
  width: 274px;
  height: 90px;
  border-radius: 16px 16px 16px 16px;
  border: 2px solid #fa2819;
  font-size: 28px;
  font-weight: 600;
  color: #fa2819;
  text-align: center;
  line-height: 90px;
  margin-left: 32px;
}
.btnBox div:nth-child(2) {
  width: 380px;
  height: 90px;
  background: #fa2819;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  font-size: 28px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  line-height: 90px;
  margin-left: 32px;
}
.delBox {
  width: 622px;
}
</style>
